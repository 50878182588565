// src/HeroSection.js
import React from 'react';
import styled from 'styled-components';
import appStoreIcon from './assets/applelogo.svg'; // Adjust the path based on where you placed the icon

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: black;
`;

const Logo = styled.div`
  font-size: 4rem;
  font-weight: bold;
  color: white;
  span {
    color: red;
  }
`;

const Tagline = styled.p`
  font-size: 1.5rem;
  margin: 20px 0;
`;


const AppStoreIcon = styled.img`
  width: 200px;
  height: 200px;
  vertical-align: middle;
  margin-right: 10px;
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <Logo>
        Flirt<span>X</span>
      </Logo>
      <Tagline>The AI Wingman</Tagline>
        <AppStoreIcon src={appStoreIcon} alt="Download on the App Store" />
    </HeroContainer>
  );
};

export default HeroSection;
